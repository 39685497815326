.card {
    text-align: left;
}

.img-featured {
    width: 250px;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 6px;
    margin-bottom: 5px;
    box-shadow: 0 5px 10px #141414;
}

.card__category {
    padding: 5px 10px;
    border-style: dashed;
    border-width: 1px;
    border-radius: 10px;
}

.card__category.orange {
    border-color: var(--orange);
}

.card {
    background-color: rgba(255, 255, 255, .04);
    border: 2px solid rgba(255, 255, 255, .04);
    border-radius: 18px;
    max-width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
}

.card .card__description {
    height: 130px;
}

::-webkit-scrollbar {
    width: 10px;
}