.marketing {
    overflow-x: hidden;
}
.marketing__head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 80px 0px;
    text-align: center;
}

.marketing__title {
    font-size: 70px;
}

.marketing__head p {
    padding: 0px 300px;
}

.marketing__head .marketing__icon {
    width: 300px;
}

.marketing__services img {
    height: 50px;
}

.marketing__serviceDetail {
    margin: 80px 0px;
}

.marketing__serviceDetail img {
    height: 115px;
}

.marketing__contact {
    text-align: center;
    color: var(--white);
}

.marketing__contact-img img {
    will-change: opacity, transform;
    opacity: 1;
    transform: scale(1);
    transition: transform 0.3s ease-in-out; /* Adjust the transition duration and easing as desired */
    transform-origin: center center;
  }
  
  /* Animation when the image is fully visible */
  .marketing__contact-img.image-visible img {
    transform: scale(1.05); /* Adjust the scale value to control the growth effect */
  }

/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {
    .service-card-detail {
        min-width: auto;
    }

    .marketing__title {
        font-size: 40px;
    }
    
    .marketing__head .marketing__icon {
        width: 250px;
    }
    
    .marketing__head p {
        padding: 0px 0px;
    }

    .marketing__serviceDetail .col {
        min-width: 100%;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {
    .marketing__title {
        font-size: 60px;
    }
    
    .marketing__head .marketing__icon {
        width: 250px;
    }
    
    .marketing__head p {
        padding: 0px 0px;
    }
}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {
    .marketing__title {
        font-size: 60px;
    }

    .marketing__head .marketing__icon {
        width: 250px;
    }

    .marketing__head p {
        padding: 0px 0px;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {
    .marketing__head p {
        padding: 0px 100px;
    }
}

@media (min-width: 1200px) {
    .marketing__contact h2 {
        padding: 0px 300px;
    }
}