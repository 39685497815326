/*PANEL DERECHO MENU PAGINA PRINCIPAL*/

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: var(--black);
    color: var(--black);
    overflow-x: hidden;
    transition: 0.3s;
    padding-top: 60px;
    margin-top: 56px;
    -webkit-box-shadow: -4px 0px 10px -3px rgba(0,0,0,0.65);
	-moz-box-shadow: -4px 0px 10px -3px rgba(0,0,0,0.65);
	box-shadow: -4px 0px 10px -3px rgba(0,0,0,0.65);
    font-family: var(--secondary-font);
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}


/*MARKETPLACE INPUT*/

.marketPlace{
    font-size: 16px;
}

.marketPlace__container{
    background-color: var(--white);
    height: 50px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    border-radius: 2em;
    margin: 10px;
}

.marketPlace__input{

    font-weight: 400;
    display: flex;
    width: 90%;
    height: 100%;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;

    
}

.input__icono{

    border: none solid var(--black);
    font-size: 16px;

}

.input__input{

    border: transparent;
    height: 100%;
    padding-left: 15px;
    font-size: 16px;
    width: 100%;
    background-color: var(--white);
}

.input__input:focus{

    outline: none;
}

.marketPlace__boton{

    padding: 0;
    background-color: var(--white);
    border: transparent;
}

/*MARKETPLACE LISTAS*/

.listaMarketPlace li{

    display: flex;
    justify-content: space-between;
    height: 64px;
}

.listaMarketPlace__lista{

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    color: var(--white);
    font-weight: 400;
    text-decoration: none;
}

.listaMarketPlace__lista:hover{
    text-decoration: none;
    color: var(--orange);
    font-weight: bold;
}

.listaMarketPlace__icono{

    font-size: 20px;
    padding-right: 10px;
}

.listaMarketPlace__texto{

    font-size: 13px;
}

/*MARKETPLACE FOOTER*/

.botonMarketPlace{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 76px; /*Son 76px por la altura de separación para le btoon de whatsapp*/
    width: 100%;
}

.botonMarketPlace__button{
    text-decoration: none;
    width: 85%;
    border: transparent;
    border-radius: 16px;
    height: 44px;
    background-color: var(--colorWA);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.5s ease-in-out;

}

.botonMarketPlace__button:hover{

    color: var(--white);
    text-decoration: none;
    background-color: var(--colorWAhover);
}

.buttonMarketPlace__icono{
    font-size: 20px;
}

.buttonMarketPlace__texto{
    margin-left: 8px;
    font-size: 16px;
}