.divPortafolio{
    overflow: hidden;
}

.portafolio_title{
    margin-bottom: 80px;
    margin-top: 80px;
}

.portafolio_title .style_subtitle{
    margin-top: 30px;
}

.portafolio_ecommerce .style_subtitle, .portafolio_sites .style_subtitle{
    text-align: left;
    margin-left: 3.5rem;
}


.portafolio_ecommerce, .portafolio_sites{
    width: 100%;
    overflow: hidden;
    margin-bottom: 80px;
}