@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&family=Questrial&display=swap');

body {
  margin: 0;
  font-family: 'Questrial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sun__background {
  z-index: -999;
  width: 100%;
  height: 100vh;
  background-image: radial-gradient(circle farthest-side at 50% -50%, #ff9900, rgba(255, 153, 0, .5) 25%, rgba(255, 153, 0, .1) 50%, #141414 75%);
  /* margin-top: 80px; */
  position: fixed;
  background-color: var(--black);
}

.service-card-detail {
  background-color: rgba(255, 255, 255, .04);
  border: 2px solid rgba(255, 255, 255, .04);
  border-radius: 16px;
  padding: 15px 25px;
  margin: 20px;
  min-width: 40%;
}

.scroll__cards {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: -20px;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  overflow: auto;
}

a {
  text-decoration: none;
  color: var(--orange);
  font-weight: bold;
}


.primary__button {
  color: var(--black);
  background-color: var(--white);
  background-image: none;
  border-radius: 12px;
  flex: none;
  padding: 12px 16px;
  font-family: Inter, sans-serif;
  font-size: 15px;
  text-decoration: none;
}

.secondary__button {
  grid-column-gap: 5px;
  color: var(--white);
  background-color: rgba(255, 255, 255, .04);
  background-image: none;
  border: 1px #000;
    border-top-color: rgb(0, 0, 0);
    border-right-color: rgb(0, 0, 0);
    border-bottom-color: rgb(0, 0, 0);
    border-left-color: rgb(0, 0, 0);
  border-radius: 12px;
  flex: none;
  align-items: center;
  padding: 12px 16px;
  font-family: Inter, sans-serif;
  font-size: 15px;
  text-decoration: none;
  display: flex;
}


.noPadding {
  padding: 0!important;
}

.noMargin {
  margin: 0!important;
}

.black-color {
  color: var(--black);
}

.orange-color {
  color: var(--orange);
}

.white-color {
  color: var(--white);
}

.gray-color {
  color: var(--gray);
}

.bg-orange{
  background-color: var(--orange);
}

.bg-dark{
  background-color: var(--dark);
}

.btn-orange{
  background-color: var(--orange);
  color: white;
}

.btn-orange:hover{
  background-color: var(--orange-hover);
  color: white;
}

.btn-dark{
  background-color: var(--dark);
  color: white;
}

.btn-dark:hover{
  background-color: var(--dark-hover);
  color: white;
}

.dark-gray-color{
  color: var(--dark-gray);
}

.form-control--black{
    height: 44px;
    color: #fff;
    text-align: left;
    background-color: rgba(255, 255, 255, .04);
    border: 1px #000;
    border-radius: 12px;
    margin-bottom: 0;
    padding: 12px 15px;
    font-size: 16px;
    line-height: 21px;
}

.form-control--black::placeholder{
    color: var(--dark-gray);
}

.form-control--black:focus {
  background-color: rgba(255, 255, 255, .04);
  color: white;
  border: 1px solid var(--dark-gray);
  box-shadow: none;
}

.font-size-lg{
  font-size: 45px;
  line-height: 105%;
}

.font-size-xl{
  font-size: 55px;
  line-height: 105%;
}

.secondary-font{
  font-family: var(--secondary-font);
}

:root {
  --black: #141414;
  --orange: #ff9900;
  --orange-hover:rgb(222, 134, 3);
  --white: #f0f0f0;
  --gray: #a3a3a3;
  --dark-gray: #888;
  --dark: rgba(255, 255, 255, .04);
  --colorWA: #075e54;
  --colorWAhover: #064b43;

  --principal-font: 'Montserrat';
  --secondary-font: 'Questrial';
}


/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {

  .col-0{
      display: none;
  }

  .noPadding-xs{
      padding:0 !important;
  }

  .pt-xs{
      padding-top: 15px;
  }

  .noMargin-xs{
      margin:0 !important;
  }

  .mt-xs{
      margin-top: 15px;
  }

  .font-size-lg{
    font-size: 28px;
  }

}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {

  .col-sm-0{
      display: none;
  }

  .noPadding-sm{
      padding:0 !important;
  }

  .pt-sm{
      padding-top: 15px;
  }

  .noMargin-sm{
      margin:0 !important;
  }

  .mt-sm{
      margin-top: 15px;
  }

  .font-size-lg{
      font-size: 28px;
  }

}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {

  .col-md-0{
      display: none;
  }

  .noPadding-md{
      padding:0 !important;
  }

  .pt-md{
      padding-top: 15px;
  }

  .noMargin-md{
      margin:0 !important;
  }

  .mt-md{
      margin-top: 15px;
  }

}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {

  .col-lg-0{
      display: none;
  }

  .noPadding-lg{
      padding:0 !important;
  }

  .pt-lg{
      padding-top: 15px;
  }

  .noMargin-lg{
      margin:0 !important;
  }

  .mt-lg{
      margin-top: 15px;
  }

}

@media (min-width: 1200px){

  .col-xl-0{
      display: none;
  }

  .noPadding-xl{
      padding:0 !important;
  }

  .pt-xl{
      padding-top: 15px;
  }
  
  .txtTiempo{
      font-size: 12px;
  }
  
  .noMargin-xl{
      margin:0 !important;
  }
  
  .mt-xl{
      margin-top: 15px;
  }
}