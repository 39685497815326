/* Estilos para la sección del efecto parallax */
.section-page-hero {
    position: relative;
    height: 100vh;
    overflow: hidden;
}
  
  /* Estilos para el contenido del efecto parallax */
.parallax-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    transition: transform 0.2s linear;
}

.cardExperiencias span{
    font-size: 60px;
}

/* PREGUNTAS FRECUENTES */

.preguntas{
    padding: 2em 0em;
    font-size: 16px;
    color: var(--white);
}

.preguntas__titulo{
    font-size: 2em;
}

#preguntas__elementos{
    margin-left: 5%;
    margin-right: 5%;;
}

.preguntas__card{
    background-color: var(--black)!important;
    border: 2px solid var(--orange)!important;
    padding: 0!important;
    margin:1em 0;
    width: 100%!important;
}

.preguntas__botonCard{
    display: flex!important;
    justify-content: space-between;
    align-items: center;
    color: var(--white) !important;
    font-size: 1em;
    width: 100%;
}

.preguntas__botonCard i{
    font-size: 2em;
    color: var(--orange)!important
}

.accordion > .preguntas__card:not(:last-of-type){
    border: 2px solid var(--orange)!important;
}
  /* Estilos para el fondo (puedes ajustar el fondo según tus necesidades) */
  /* .section-page-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: -1;
  } */
  
  /* Efecto de parallax */
  @media screen and (min-width: 768px) {
    .cardExperiencias span{
        font-size: 100px;
    }

    #preguntas__elementos{
        margin-left: 20%;
        margin-right: 20%;;
    }
  }
  