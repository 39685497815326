#contacto-view{
    font-family: var(--principal-font);
    text-align: center;
}

#contacto-view .victory-sign{
    width: 65px;
    display: block;
    margin: 0 auto;
}

.seccion-contacto {
    max-width: 1440px;
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    background-color: rgba(255, 255, 255, .04);
    border-radius: 24px;
    flex-direction: column;
    margin-top: 60px;
    margin-left: 18%;
    margin-right: 18%;
    padding: 60px 5% 5vw;
    display: flex;
    color: white;
}

.checkbox{
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.checkbox + label {
    padding: 5px 12px;
    font-size: 13px;
    border: 1px solid rgba(255, 255, 255, .04);
    cursor: pointer;
}

.checkbox:checked + label {
    border: 1px solid var(--orange);
    border-radius: 12px;
}

.checkbox-wrapper{
    width: 100%;
    grid-column-gap: 7.5px;
    grid-row-gap: 7.5px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
}


/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {
    .seccion-contacto {
        margin-left: 3%;
        margin-right: 3%;
    }

    #contacto-view .victory-sign{
        width: 45px;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {

    .seccion-contacto {
        margin-left: 3%;
        margin-right: 3%;
    }

    #contacto-view .victory-sign{
        width: 45px;
    }

}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {


}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {


}

@media (min-width: 1200px){

}