.webDev .marketing__contact {
    width: 100%;
    overflow: hidden;
}

.webDev {
    overflow: hidden;
}

/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {

}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {

}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {
    .cursodontic__logo {
        width: 80%;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {
    .cursodontic__logo {
        width: 50%;
    }
}

@media (min-width: 1200px){
    .cursodontic__logo {
        width: 50%;
    }
}