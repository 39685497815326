.cabezote{
    background-color: var(--black);
    height: 56px;
    padding: 15px;  
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: var(--secondary-font);
}

.cabezote__menu{
    display: flex;
}

.cabezote__login{
    width: 110px;
    margin-left: 24px;
}

.listaCabezote__link{
    text-decoration: none;
}

.menu__exit{
    background-color: var(--black);
    border: var(--black);
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 0;
}

.menu__logo{
    color: var(--white);
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-left: 8px;
}

.menu__logo:hover{
    color:var(--white)
}


.menu__icono{
    color: var(--white);
    font-size: 24px;
}

.menu__icono.fa-times{
    width: 21px;
}


.logo__imagen{
    height: 40px;
    margin-left: 5px;
}

.logo__texto{
    font-size: 24px;
    padding-left: 4px;
    margin: 0;
    font-weight: bold;
    font-family: var(--secondary-font);
    /* color: var(--gray); */
}

/*AJUSTES DEL NAVBAR*/

.cabezoteInfo{

    display: flex;
    align-items: center;
}

.cabezoteInfo__buscador{

    width: 300px;
    height: 30px;
    border-bottom: 2px var(--white) solid;
    margin-left: 50px;
}

.buscador__icono{

    color: var(--white);
    font-size: 20px;
    margin-right: 10px;
    margin-left: 6px;
}

.buscador__input{

    width: 250px;
    height: 30px;
    border-bottom: 2px white solid;
    border-left: none;
    border-right: none;
    border-top: none;
    background-color: var(--black);
    color: var(--white);
}

.buscador__input:focus{

    outline: none;
}

.cabezoteInfo__listaCabezote{
    display: flex;
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
}

.listaCabezote__referenciaCursodontic{
    display: flex;
    align-items: center;
}

.referenciaCursodontic__icono{

    color: var(--white);
    font-size: 17px;
    margin-right: 8px;
}

.referenciaCursodontic__text{
    
    color: var(--white);
    font-size: 16px;
    padding-right: 25px;
}

.referenciaCursodontic__img{
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

/*RESPONSIVE*/

/*XS*/ 
@media (max-width: 575px) {
    .logo__texto{
        font-size: 20px;
    }

    .logo__imagen{
        height: 35px;
        margin-left: 0;
    }

    .col-xs-0{

        display: none;
    }
}
/*SM*/
@media (max-width: 767px) and (min-width: 576px) {
    
    .col-sm-0{
        display: none;
    }

    .logo__imagen{

        margin-left: 0;
    }
    
}
/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .col-md-0{
        display: none;
    }

    .logo__imagen{

        margin-left: 0;
    }

    .cabezoteInfo__buscador{

        width: 235px;
        margin-left: 30px;
    }

    .buscador__icono{

        font-size: 14px;
        margin-right: 6px;
        margin-left: 6px;
    }

    .buscador__input{

        width: 205px;
        font-size: 14px;
    }
    
}
/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .col-lg-0{

        display: none;
    }

    .cabezoteInfo__buscador{

        width: 201px;
        height: 30px;
        border-bottom: 2px var(--white) solid;
        margin-left: 30px;
    }

    .buscador__icono{

        font-size: 14px;
        margin-right: 6px;
        margin-left: 6px;
    }

    .buscador__input{

        width: 175px;
        font-size: 13px;
    }

    .referenciaCursodontic__icono{

        font-size: 17px;
    }

    .referenciaCursodontic__text{

        font-size: 14px;
        padding-right: 12px;
    }
    
}
/*XL*/
@media (min-width: 1200px){

    .col-xl-0{

        display: none;
    }
    
}