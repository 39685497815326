.divPrincipal{
    overflow: hidden;
}

.divPrincipal .container_proyects{
    overflow: hidden;
    width: 100%;
}

.parallax_container{
    width: 100%;
    perspective: 8px;
    perspective-origin: 50%;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
}

.style_title {
    margin: 0px !important;
    color: #ffffff;
    font-size: 50px; /*30 en responsive*/
    font-weight: 600;
    line-height: 50px;
}

.style_subtitle{
    color: #fff;
    text-align: center;
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 34px; /*14 en responsive*/
    font-weight: 600;
    line-height: 105%;
}

.banner_text{
    width: 500px;
    position: absolute;
    font-family: var(--secondary-font);
    top: 40%;
    left: 27%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 1;
}

.banner_subtext{
    color: white;
    background: rgba(0, 0, 0, 0.318);
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 40px;
    /* padding: 0 15px; */
}

.div_image_banner{
    overflow: hidden;
    height: 95vh;
    position: relative;
}

.image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(128, 128, 128, 0.5); /* Esto agrega una capa gris semi-transparente */
}


.image_banner{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 20%;
    transform-origin: center;
    object-fit: contain;
}


/* Texto animado */


.typed_text {
    border-right: 2px solid black;
    white-space: nowrap;
    overflow: hidden;
    color: var(--orange) !important;
}
  
/* Optional blinking cursor animation */
@keyframes blink-caret {
    50% {
        border-color: transparent;
    }
}


/* Fin de texto animado */


/* Inicio del div empresas */
.horizontal_scroll_container {
    width: 100%;
    max-width: 1440px;
    margin-top: 60px;
    display: block;
    overflow: hidden;
}

.horizontal-scroll-container-large{
    margin-left: auto;
    margin-right: auto;
}

.horizontal_scroll_container_content {
    width: auto;
    grid-column-gap: 100px;
    grid-row-gap: 100px;
    grid-template-rows: auto;
    grid-template-columns: max-content 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
    display: flex;
    animation: scrollRight 15s linear infinite;
}

.horizontal_scroll_container_content img{
    max-height: 40px;
    margin-right: 40px;
    flex-shrink: 0;
}

@keyframes scrollRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* Fin del div empresas */


/* Inicio de Texto "Te hemos cubierto" */

.cover_container{
    margin: 80px auto;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    /* width: 60%; */
}

.cover_container_text{
    max-width: 800px;
    text-align: center;
    color: #888;
    margin-bottom: 0px;
    padding-top: 0;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
}

/* Fin de Texto "Te hemos cubierto" */


/* Inicio de cards de servicios */

.services_container{
    margin-top: 150px;
}

.service_card{
    max-width: 350px;
    min-width: 250px;
    background-color: rgba(255, 255, 255, .04);
    border: 2px solid rgba(255, 255, 255, .04);
    border-radius: 12px;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    margin: auto;
}

.service_card_content{
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: -40px;
    display: flex;
    position: static;
}

.service_card_content_icon{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.service_card .btn-secondary, .container_proyects .btn-secondary {
    grid-column-gap: 5px;
    color: #fff;
    background-color: rgba(255, 255, 255, .04);
    background-image: none;
    border: 1px #000;
    border-radius: 12px;
    flex: none;
    align-items: center;
    padding: 12px 16px;
    font-family: Inter, sans-serif;
    font-size: 15px;
    text-decoration: none;
    display: flex;
}

.service_card .btn-secondary:hover, .container_proyects .btn-secondary:hover {
    background-color: #141414;
    background-image: none;
    border-color: #141414;
}

/* Fin de cards de servicios */



/* Inicio container metodologia */
.container_methodology {

    margin-top: 200px;
    margin-bottom: 200px;

}

.container_methodology_title{
    margin-bottom: 50px !important;
}

.container_methodology .btn-primary{
    color: #141414;
    background-color: #fff;
    background-image: none;
    border-radius: 12px;
    flex: none;
    padding: 12px 16px;
    font-size: 15px;
    text-decoration: none;
}

.container_methodology .btn-primary:hover {
    background-color: var(--orange);
    background-image: none;
    color: white;
}

.container_methodology_points{
    max-width: 800px;
    color: #fff;
    margin-bottom: 0px;
    padding-top: 0;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
}

.container_methodology_text{
    max-width: 800px;
    color: #888;
    margin-bottom: 0px;
    padding-top: 0;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
}

/* Fin metodologia  */


/* Inicio proyectos */
.container_proyects{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.container_proyects .btn-secondary {
    width: 170px;
    text-align: center !important;
    margin-bottom: 80px;
}


.container_proyects_carousel{
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
}

.container_proyects_carousel .style_title{
    margin-bottom: 30px !important;
}


/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {
    .banner_text{
        left: 32%;
        width: 250px;
        top: 40%;
    }

    .style_title {
        font-size: 25px;
        line-height: 30px;
    }
    .horizontal_scroll_container_content {
        animation: scrollRight 15s linear infinite;
        justify-content:start;
        padding-left: 20px;
    }
    .horizontal_scroll_container_content img{
        max-height: 35px;
        margin-right: 15px;
    }
    .service_card{
        margin-bottom: 90px;
    }
    .container_methodology {
        margin-top: 100px;
        text-align: center;
    }
    .banner_subtext{
        font-size: 12px;
        line-height: 20px;
        margin-top: 20px;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {
    .banner_text{
        left: 28%;
        width: 290px;
        top: 40%;
    }

    .style_title {
        font-size: 30px;
        line-height: 30px;
    }
    .horizontal_scroll_container_content {
        animation: scrollRight 15s linear infinite;
        justify-content:start;
        padding-left: 20px;
    }
    .horizontal_scroll_container_content img{
        max-height: 35px;
        margin-right: 15px;
    }
    .service_card{
        margin-bottom: 90px;
    }
    .container_methodology {
        margin-top: 100px;
        text-align: center;
    }
    .banner_subtext{
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;
    }
}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {
    .banner_text{
        left: 30%;
        width: 390px;
    }

    .style_title {
        font-size: 40px;
        line-height: 40px;
    }
    .horizontal_scroll_container_content {
        animation: scrollRight 15s linear infinite;
        justify-content:start;
        padding-left: 20px;
    }
    .horizontal_scroll_container_content img{
        max-height: 35px;
        margin-right: 20px;
    }
    .service_card{
        margin-bottom: 90px;
    }
    .container_methodology {
        margin-top: 100px;
    }
    .banner_subtext{
        font-size: 14px;
        line-height: 20px;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {
    .banner_text{
        left: 32%;
    }
    .style_title {
        font-size: 50px;
    }
}

@media (min-width: 1200px) {
    .style_title {
        font-size: 50px;
    }
    
}